<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-if="vendor">
      <div class="add-property-title"> {{ $t('settings') }}</div>
      <div class="row">
        <div class="col-md-12 input-item">
          <div class="input-title-item"> {{ $t('vendor-name') }}</div>
          <div class="col-md-6">
            <InputField :inputtype="'text'" v-model="vendor.name" :content="vendor.name"
                  :label="$t('enter-name')" :error="formErrors.includes('name')"/>
          </div>

        </div>
        <div class="col-md-6 input-item">
          <div class="input-title-item"> Email </div>
          <div v-for="(email, index) in vendor.emailCollection" class="mb-3">
            <InputField :inputtype="'text'" v-model="vendor.emailCollection[index]" :content="email"
                        :label="$t('enter-email-placeholder')"/>
          </div>
          <div class="add-button">
            <Button :size="'small'" :theme="'solid-grey'" :text="$t('add-email-button')" :svg="'plus'" @click="addMail()"/>
          </div>
        </div>
        <div class="col-md-6 input-item">
          <div class="input-title-item"> {{ $t('contact-phone') }}</div>
          <div>
            <div v-for="(phone, index) in vendor.phoneCollection" class="mb-3">
              <InputField :inputtype="'text'" v-model="vendor.phoneCollection[index]" :content="phone"
                          :label="$t('enter-phone-placeholder')"/>
            </div>
          </div>
          <div class="add-button">
            <Button :size="'small'" :theme="'solid-grey'" :text="$t('add-phone-button')" :svg="'plus'" @click="addPhone()"/>
          </div>
        </div>
        <div class="col-md-6 input-item" v-if="false">
          <div class="input-title-item"> {{ $t('address') }}</div>
          <div class="">
            <InputField :inputtype="'text'" v-model="vendor.address" :content="vendor.address"
                        :label="$t('enter-address-placeholder')" :error="formErrors.includes('address')"/>
          </div>
        </div>
        <div class="col-md-6 input-item" v-if="false">
          <div class="input-title-item"> {{ $t('eic-bulstat') }}</div>
          <div class="">
            <InputField :inputtype="'number'" :label="$t('enter-eic-placeholder')"/>
          </div>
        </div>
        <div class="col-md-6 input-item" v-if="false">
          <div class="input-title-item">IBAN</div>
          <div class="">
            <InputField :inputtype="'text'" :label="$t('enter-iban-placeholder')"/>
          </div>
        </div>
        <div class="col-md-6 input-item" v-if="false">
          <div class="input-title-item">{{ $t('bank') }}</div>
          <div class="">
            <InputField :inputtype="'text'" :label="$t('enter-iban-placeholder')"/>
          </div>
        </div>
        <div class="col-md-12 input-item">
          <div class="input-title-item"> {{ $t('profile-picture') }}</div>
          <div class="d-flex">
<!--            <div style="width: 100px; height: 100px; background: #ccc" class="me-4">-->
<!--              <div class="thumb-image" v-bind:style="{ 'background-image': 'url(/storage/' + vendor.profilePicture + ')' }">-->
<!--              </div>-->
<!--              <div class="x-icon" @click="removeImage()">-->
<!--                <xIcon/>-->
<!--              </div>-->
<!--            </div>-->
            <div class="flex-grow-1" style="max-width: 500px;">
              <ImageInput :image="vendor.profilePicture" v-model="vendor.profilePicture" @change="e => uploadPhoto(e)"/>
            </div>
          </div>
        </div>
        <div class="col-md-12 input-item row ">
          <div class="input-title-item"> {{ $t('social-networks') }}</div>
          <div class="input-item col-md-6 ">
            <div class="amenity-item">Facebook</div>
            <InputField :inputtype="'text'" v-model="vendor.facebook" :content="vendor.facebook"
                        :label="$t('enter-link')" :error="formErrors.includes('facebook')"/>
          </div>
          <div class="input-item col-md-6 ">
            <div class="amenity-item">Instagram</div>
            <InputField :inputtype="'text'" v-model="vendor.instagram" :content="vendor.instagram"
                        :label="$t('enter-link')" :error="formErrors.includes('instagram')"/>
          </div>
        </div>
        <div class="col-md-12 input-item ">
          <div class="input-title-item"> {{ $t('basic-info') }}</div>
          <div class="input-item ">
            <TextArea :label="$t('basic-info-placedholder')" v-model="vendor.description" :content="vendor.description"
                      :error="formErrors.includes('description')"/>
          </div>
        </div>
        <div class="add-property-title"> {{ $t('additional-info') }}</div>
        <div class="col-md-12 input-item ">
          <div class="input-title-item"> {{ $t('delivery') }}</div>
          <div class="input-item ">
            <TextArea :label="$t('delivery-placeholder')" v-model="vendor.deliveryInfo" :content="vendor.deliveryInfo" :error="formErrors.includes('deliveryInfo')"/>
          </div>
        </div>
        <div class="col-md-12 input-item ">
          <div class="input-title-item"> {{ $t('warranty-conditions') }}</div>
          <div class="input-item ">
            <TextArea :label="$t('warranty-conditions-placeholder')" v-model="vendor.warranty" :content="vendor.warranty"
                      :error="formErrors.includes('warranty')"/>
          </div>
        </div>
        <div class="col-md-12 input-item ">
          <div class="input-title-item"> {{ $t('return-policy') }}</div>
          <div class="input-item ">
            <TextArea :label="$t('return-policy-placeholder')" v-model="vendor.returnPolicy" :content="vendor.returnPolicy" :error="formErrors.includes('returnPolicy')"/>
          </div>
        </div>
        <div class="col-md-12 input-item ">
          <div class="input-title-item"> {{ $t('gdpr') }}</div>
          <div class="input-item ">
            <TextArea :label="$t('gdpr-placeholder')" v-model="vendor.privacyPolicy" :content="vendor.privacyPolicy" :error="formErrors.includes('privacyPolicy')"/>
          </div>
        </div>
        <div class="col-md-12 input-item password-wrapper">
          <div class="input-title-item"> {{ $t('change-password') }}</div>
          <div class="input-description ">
            {{ $t('change-password-paragraph') }}
          </div>
          <div class=" col-md-5">
            <Button :size="'medium'" :theme="'solid-grey'" :text="$t('change-password')" @click="changePassword"/>
          </div>
        </div>
        <div class=" premium-item button-save col-md-4">
          <Button :theme="'solid-green'" :size="'medium'" :text="$t('save')" @click="save()"/>
        </div>
      </div>
  </div>
</template>

