import Button from "@/components/Common/Buttons/Button/Button";
import InputField from "@/components/Common/Inputs/Input/Input";
import FileInput from "@/components/Common/Inputs/FileInput/FileInput";
import TextArea from "@/components/Common/Inputs/TextArea/TextArea";
import FileService from '@/services/FileService';
import xIcon from "@/components/Common/Icons/xIcon.vue";
import ImageInput from "@/components/Common/Inputs/ImageInput/ImageInput";


export default {
    name: 'VendorProfileSettings',
    components: {
        ImageInput,
        Button,
        TextArea,
        InputField,
        FileInput,
        xIcon,
    },
    data() {
        return {
            vendor: {},
            user: {},
            userPassword: {
                password: null,
                password2: null,
            },
            formErrors: [],
            passFormErrors: [],
        };
    },
    methods: {
        async save() {
            if (!this.$store.state.user.authenticated) {
                await this.$store.dispatch("utils/openLoginModal");
                this.$router.push({name: 'Index'});
                return;
            }

            this.formErrors = [];
            if (this.vendor.name === null || this.vendor.name === '') {
                this.formErrors.push('name');
            }
            if (this.vendor.address === null || this.vendor.address === '') {
                this.formErrors.push('address');
            }
            if (this.vendor.warranty === null || this.vendor.warranty === '') {
                this.formErrors.push('warranty');
            }
            if (this.vendor.deliveryInfo === null || this.vendor.deliveryInfo === '') {
                this.formErrors.push('deliveryInfo');
            }
            if (this.vendor.description === null || this.vendor.description === '') {
                this.formErrors.push('description');
            }
            if (this.vendor.returnPolicy === null || this.vendor.returnPolicy === '') {
                this.formErrors.push('returnPolicy');
            }
            if (this.vendor.privacyPolicy === null || this.vendor.privacyPolicy === '') {
                this.formErrors.push('privacyPolicy');
            }
            if (this.formErrors.length > 0) {
                return;
            }

            try {
                await this.$store.dispatch("user/updateVendor", this.vendor);
                this.$store.dispatch("utils/openNotificationModal", {
                    type: 'success',
                    message: this.$t('your-profile-was-successfully-updated')
                });
            } catch (error) {
                this.$store.dispatch("utils/openNotificationModal", {
                    type: 'error',
                    message: this.$t('error-try-again')
                });
            }
        },
        async changePassword() {
            try {
                await this.$store.dispatch("user/requestChangePasswordMail", {email: this.user.email});
                this.$store.dispatch("utils/openNotificationModal", {
                    type: 'success',
                    message: this.$t('password-reset-instructions-sent-to-mail')
                });
            } catch (error) {
                this.$store.dispatch("utils/openNotificationModal", {
                    type: 'error',
                    message: this.$t('error-try-again')
                });
            }
        },
        async uploadPhoto(event) {
            const files = event.target.files;
            const formData = new FormData();
            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                formData.append('file[]', file, file.name);
            }
            const images = await FileService.uploadFile(formData);
            if (images.length < 1) {
                return;
            }
            this.vendor.profilePicture = images[0].src;
        },
        addMail() {
            this.vendor.emailCollection.push('');
        },
        addPhone() {
            this.vendor.phoneCollection.push('');
        },
        removeImage() {
            this.vendor.profilePicture = null;
        }
    },
    async mounted() {
        this.user = await this.$store.dispatch("user/loadAuthenticatedUser");
        this.vendor = this.user.vendor;
    },
};
